import { ref, onMounted } from "vue";
export default {
  name: "SimpleToast",
  props: {
    text: {
      type: String,
      default: ""
    },
    duration: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "success"
    }
  },
  setup: function setup(props) {
    var isMobile = function isMobile() {
      var info = navigator.userAgent;
      var agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];

      for (var i = 0; i < agents.length; i++) {
        if (info.indexOf(agents[i]) >= 0) {
          return true;
        }
      }

      return false;
    };

    var visible = ref(false);
    onMounted(function () {
      visible.value = true;
      setTimeout(function () {
        visible.value = false;
      }, props.duration - 800);
    });
    return {
      visible: visible,
      isMobile: isMobile
    };
  }
};